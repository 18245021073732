<template>
    <div class="main-wrap">
        <slot/>
    </div>
</template>

<script>

export default ({
    name: 'BlankLayout'
})
</script>
